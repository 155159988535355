// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-australia-bushfires-infographic-js": () => import("./../../../src/pages/australia-bushfires-infographic.js" /* webpackChunkName: "component---src-pages-australia-bushfires-infographic-js" */),
  "component---src-pages-carbon-emissions-js": () => import("./../../../src/pages/carbon-emissions.js" /* webpackChunkName: "component---src-pages-carbon-emissions-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-easy-talk-js": () => import("./../../../src/pages/easy-talk.js" /* webpackChunkName: "component---src-pages-easy-talk-js" */),
  "component---src-pages-grid-3-js": () => import("./../../../src/pages/grid-3.js" /* webpackChunkName: "component---src-pages-grid-3-js" */),
  "component---src-pages-grub-run-js": () => import("./../../../src/pages/grub-run.js" /* webpackChunkName: "component---src-pages-grub-run-js" */),
  "component---src-pages-herups-js": () => import("./../../../src/pages/herups.js" /* webpackChunkName: "component---src-pages-herups-js" */),
  "component---src-pages-humaneos-js": () => import("./../../../src/pages/humaneos.js" /* webpackChunkName: "component---src-pages-humaneos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legistar-redesign-js": () => import("./../../../src/pages/legistar-redesign.js" /* webpackChunkName: "component---src-pages-legistar-redesign-js" */),
  "component---src-pages-seattle-crime-rate-js": () => import("./../../../src/pages/seattle-crime-rate.js" /* webpackChunkName: "component---src-pages-seattle-crime-rate-js" */),
  "component---src-pages-watch-it-js": () => import("./../../../src/pages/watch-it.js" /* webpackChunkName: "component---src-pages-watch-it-js" */)
}


import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Title, Text } from "../../components/Core";
//import imgPhoto from "../../assets/image/jpg/portrait-about.jpg";

import "../../assets/fonts/icon-font/css/style.css";

const LinkSocial = styled.a`
  color: ${({ theme }) => theme.colors.light} !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 1.63px;
  transition: 0.4s;
  &:hover {
    color: ${({ theme }) => theme.colors.light} !important;
    transform: translateY(-5px);
  }
`;

const About = ({ hero = true, bg = "dark", ...rest }) => {
  return (
    <>
      <Section hero={hero} bg={bg} {...rest} >
        <Container css={`margin-bottom: -15px;`}>
          <Row className="align-items-center" css={`margin-top: -15px;`}>
            <Col lg="4">
              <div>
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/c_scale,h_1500/v1613034565/portrait-about.png"} alt="Portrait of Ryan Dang" className="img-fluid" />
              </div>
            </Col>
            <Col lg="8">
              <div className="pl-lg-4 pt-5 pt-lg-0">
                <Title color="light" variant="cardBig">
                  About Me
                </Title>
                <Text
                  variant="p"
                  color="light"
                  className="mt-3 mt-lg-4"
                  css={`
                    font-size: 18px;
                    line-height: 1.5;
                  `}
                >
                  Hello! My name is Ryan and I am a designer based in Seattle, Washington.
                  <Text
                    variant="p"
                    color="light"
                    css={`
                      font-size: 18px;
                      line-height: 1.5;
                      margin-top: 10px;
                    `}
                  >
                    I am a recent graduate at the University of Washington with a Bachelor of Science in
                    <a href="https://ischool.uw.edu/programs/informatics" target="_blank">
                    <span className="hover" style={{ color: "#b7a57a", fontWeight: "bold" }}> Informatics </span></a>
                    along with concentrations in
                    <a href="https://ischool.uw.edu/programs/informatics/degree-options#hci" target="_blank">
                    <span className="hover" style={{ color: "#b7a57a", fontWeight: "bold" }}> Human-Computer Interaction </span></a> & 
                    <a href="https://ischool.uw.edu/programs/informatics/degree-options#iac" target="_blank">
                    <span className="hover" style={{ color: "#b7a57a", fontWeight: "bold" }}> Information Assurance and Cybersecurity</span></a>.
                    <Text
                      variant="p"
                      color="light"
                      css={`
                        font-size: 18px;
                        line-height: 1.5;
                        margin-top: 10px;
                      `}
                    >
                      In my extended family, I was one of the few growing up in a time where information and technology was
                      booming. Everyone relied on me for assistance when faced with issues accessing information online 
                      or utilizing new technologies. I never once felt annoyed. In fact, I was excited at the opportunity 
                      of tackling a new problem. I came to realize that my passion was something that I’ve always had. 
                      To create and find solutions that can make someone's day a little bit easier.
                    </Text>
                    <Text
                      variant="p"
                      color="light"
                      css={`
                        font-size: 18px;
                        line-height: 1.5;
                        margin-top: 10px;
                      `}
                    >
                      My past four years in university have been committed to furthering my skill sets and knowledge. I've been  
                      constantly working on creating solutions to problems through UX design, development, research, and teamwork.
                    </Text>
                    <Text
                      variant="p"
                      color="light"
                      css={`
                        font-size: 18px;
                        line-height: 1.5;
                        margin-top: 10px;
                      `}
                    >
                      In the future, I hope to make an impact through the usage of technology and human values that helps
                      people in all parts of the world beyond those around me.
                    </Text>
                  </Text>
                </Text>
                <div className="mt-5 d-flex">
                  <LinkSocial
                    href="mailto:ryandaaang@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    email
                  </LinkSocial>
                  <LinkSocial
                    href="https://www.linkedin.com/in/ryandaaang/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    linkedin
                  </LinkSocial>
                  <LinkSocial
                    href="https://github.com/daaangryan"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-weight-bold"
                  >
                    github
                  </LinkSocial>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default About;
